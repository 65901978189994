<template>
  <ContentWrapper id="page">
    <div class="content-heading">
      {{ title }}
    </div>
    <div class="card card-default">
      <div class="card-body">
        <el-form ref="pageForm" :model="currentPage" :rules="pageFormRule" labelWidth="3rem">
          <el-form-item label="標題">
            <el-input v-model="currentPage.title" clearable/>
          </el-form-item>

          <el-form-item label="內容">
              <quill-editor v-model="currentPage.content"/>
          </el-form-item>

          <el-form-item>
            <button type="button" class="btn btn-primary" @click="save()">保存
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'Page',
  data() {
    return {
      title: '',
      currentPage: {
        id: null,
        name: '',
        title: '',
        content: '',
      },
      pageFormRule: {},
    };
  },
  watch: {
    '$route.name': {
      handler(name) {
        const loading = getLoadingInstance();
        let promise;
        switch (name) {
          case 'delivery-terms':
            this.title = '運貨條款';
            promise = this.$api.information.getDeliveryTerms();
            break;
          case 'privacy-policy':
            this.title = '隱私政策';
            promise = this.$api.information.getPrivacyPolicy();
            break;
          case 'credit-card-terms':
            this.title = '信用卡條款';
            promise = this.$api.information.getCreditCardTerms();
            break;
          case 'user-agreement':
            this.title = '用戶協議';
            promise = this.$api.information.getUserAgreement();
            break;
          default:
            this.$router.replace({ name: '404' });
            break;
        }
        promise.then(({ data: response }) => {
          this.currentPage = { ...response.data };
        }).catch(errors => showErrorMessage(errors)).finally(() => {
          loading.close();
        });
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      // eslint-disable-next-line no-unused-vars
      let promise;
      // eslint-disable-next-line default-case
      switch (this.$route.name) {
        case 'delivery-terms':
          this.title = '運貨條款';
          promise = this.$api.information.saveDeliveryTerms(this.currentPage);
          break;
        case 'privacy-policy':
          this.title = '隱私政策';
          promise = this.$api.information.savePrivacyPolicy(this.currentPage);
          break;
        case 'credit-card-terms':
          this.title = '信用卡條款';
          promise = this.$api.information.saveCreditCardTerms(this.currentPage);
          break;
        case 'user-agreement':
          this.title = '用戶協議';
          promise = this.$api.information.saveUserAgreement(this.currentPage);
          break;
      }
      const loading = getLoadingInstance();
      promise.then(() => {
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">
  #page {
    .ql-container {
      height: 30rem;
    }
    .ql-toolbar {
      line-height: normal;
    }
  }
</style>
