<template>
  <ContentWrapper>
    <div class="content-heading">
      系統消息列表
    </div>

    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <button type="button" class="btn btn-primary" @click="addInformation()">添加消息
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-default">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th>標題</th>
            <th>創建時間</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in information" :key="i.id">
            <td>{{ i.title }}</td>
            <td>{{ i.created_at }}</td>
            <td>
              <el-button type="warning" @click="editInformation(i.id)">
                編輯
              </el-button>
              <el-button type="danger" @click="removeInformation(i.id)">
                刪除
              </el-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog
        id="SystemInformation"
        :title="dialogTitle"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        top="1vh"
        :append-to-body="true"
    >
      <el-form :model="informationForm" :rules="rules" ref="informationForm" label-width="150px">
        <el-form-item label="標題" prop="title">
          <el-input v-model="informationForm.title" placeholder="輸入標題"/>
        </el-form-item>
        <el-form-item label="內容" prop="content">
          <div class="card card-default">
            <div class="card-body">
              <quill-editor v-model="informationForm.content"/>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </ContentWrapper>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { getLoadingInstance } from '@/helpers';

export default {
  name: 'Information',
  mounted() {
    this.$store.dispatch('fetchInformation');
  },
  data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      informationForm: {
        title: '',
        content: '',
      },
      rules: {
        title: { required: true, message: '请输入標題', trigger: 'blur' },
        content: { required: true, message: '请輸入內容', trigger: 'blur' },
      },
    };
  },
  computed: {
    ...mapState({
      information: state => state.information.information,
    }),
  },
  methods: {
    addInformation() {
      this.dialogTitle = '添加系統消息';
      this.dialogVisible = true;
      this.informationForm = {
        title: '',
        content: '',
      };
    },
    editInformation(id) {
      this.informationForm = { ..._.find(this.information, { id }) };
      this.dialogTitle = '編輯系統消息';
      this.dialogVisible = true;
    },
    removeInformation(id) {
      this.$confirm('你確定要刪除這條數據嗎？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('removeInformation', id).then(() => {
          this.$notify.success({
            title: '消息已刪除',
          });
        }).catch((e) => {
          this.$notify.error({
            message: e.response.data.message,
          });
        });
      });
    },
    save() {
      if (this.$refs.informationForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$store.dispatch('saveInformation', this.informationForm).then(() => {
            this.$notify.success({
              title: '消息已保存',
            });
          }).catch((e) => {
            this.$notify.error({
              message: e.response.data.message,
            });
          }).finally(() => {
            loading.close();
            this.dialogVisible = false;
          });
        }
      }));
    },
  },
};
</script>

<style lang="scss">
  #SystemInformation {
    .ql-container {
      height: 30rem;
    }
    .ql-toolbar {
      line-height: normal;
    }
  }
</style>
